import { render, staticRenderFns } from "./Story.vue?vue&type=template&id=18ecb72d&scoped=true&v-on%3Ascroll=handleScroll&"
import script from "./Story.vue?vue&type=script&lang=js&"
export * from "./Story.vue?vue&type=script&lang=js&"
import style0 from "./Story.vue?vue&type=style&index=0&id=18ecb72d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18ecb72d",
  null
  
)

export default component.exports