import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "../firebase";

// Home
import Landing from "../pages/Landing.vue";
import Login from "../pages/Login.vue";
import ManageAccount from "../pages/ManageAccount.vue";
import Register from "../pages/Register.vue";
import Story from "../pages/Story.vue";
import PizzaParlor from "../pages/PizzaParlor.vue";
import Roost from "../pages/Roost.vue";
import RoostPrivacy from "../pages/RoostPrivacy.vue";
import Games from "../pages/Games.vue";
import BaleOutPrivacy from "../pages/BaleOutPrivacy.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/manage",
    name: "Manage Account",
    component: ManageAccount,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/story",
    name: "Story",
    component: Story,
  },
  {
    path: "/pizza-parlor",
    name: "Pizza Parlor",
    component: PizzaParlor,
  },
  {
    path: "/roost",
    name: "Roost",
    component: Roost,
  },
  {
    path: "/roost-privacy",
    name: "Roost Privacy",
    component: RoostPrivacy,
  },
  {
    path: "/baleout-privacy",
    name: "Bale Out Privacy",
    component: BaleOutPrivacy,
  },
  {
    path: "/games",
    name: "Games",
    component: Games,
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => {
    return x.meta.requiresAuth;
  });

  if (requiresAuth && !auth.currentUser) {
    next("/login");
  } else {
    next();
  }
});

export default router;
