<template>
  <div class="roost-container">
    <v-container>
      <v-row justify="center">
        <v-col>
          <!-- <h1 class="pizza-parlor-title text-center primary--text">Pizza Parlor</h1> -->
          <v-img
            class="align-start text-center mb-6"
            max-height="300px"
            contain
            :src="require('../assets/roost-title.png')"
          >
          </v-img>
          <h3 class="text-center primary--text mb-1">
            Become a Roost-er!
          </h3>
          <h3
            class="text-center primary--text mb-6"
            style="text-decoration: underline;"
          >
            Find it on the iOS App Store!
          </h3>
          <!-- <v-img
            class="align-start text-center"
            max-height="400px"
            contain
            :src="require('../assets/pizza-parlor.png')"
          >
          </v-img> -->
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-6">
        <v-btn
          outlined
          class="primary--text"
          style="text-decoration: underline; margin: 6px;"
          href="mailto:jacob@superjdigital.com"
        >
          Support
        </v-btn>
        <v-btn
          outlined
          class="primary--text"
          style="text-decoration: underline; margin: 6px;"
          to="roost-privacy"
        >
          Privacy
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Roost",
};
</script>
<style lang="scss">
@import "../styles/custom_fonts.scss";
</style>
<style>
.roost-container {
  background: #50311d;
  height: 100%;
  width: 100%;
  margin: 0;
}
.dairy-title {
  color: skyblue;
}
.nav-title {
  font-size: 18px !important;
}
.v-btn:before {
  background-color: unset !important;
}
.divider {
  border-width: 2px;
}
.card-title-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
.paragraph {
  max-width: 1000px;
}
.screen-height {
  height: 87vh;
}

/* Text */
.primary--text {
  color: #c9a485;
}
</style>
