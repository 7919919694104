<template>
  <div>
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="2">
          <v-img
            contain
            max-height="56px"
            :src="require('../assets/baleout-cow.png')"
          >
          </v-img>
        </v-col>
        <v-col align="center" cols="8">
          <span class="dairy-title"> Games </span>
        </v-col>
        <v-col cols="2">
          <v-img
            contain
            max-height="56px"
            :src="require('../assets/baleout-pig.png')"
          >
          </v-img>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col align="center">
          <v-card
            elevation="12"
            tile
            class="mx-auto my-12"
            width="560"
            height="175"
          >
            <iframe
              frameborder="0"
              src="https://itch.io/embed/2061247?border_width=0&amp;bg_color=87ceeb&amp;fg_color=000000&amp;link_color=417254&amp;border_color=87ceeb"
              width="550"
              height="165"
              ><a href="https://super-j-digital.itch.io/bale-out"
                >Bale Out by Super J Digital</a
              ></iframe
            >
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-6">
        <v-btn
          outlined
          class="primary--text"
          style="text-decoration: underline; margin: 6px"
          href="mailto:jacob@superjdigital.com"
        >
          Support
        </v-btn>
        <v-btn
          outlined
          class="primary--text"
          style="text-decoration: underline; margin: 6px"
          to="baleout-privacy"
        >
          Privacy
        </v-btn>
      </v-row>
      <v-row justify="center">
        <v-col align="center">
          <h3>More coming soon!</h3>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Parlor",
};
</script>
<style lang="scss">
@import "../styles/custom_fonts.scss";
</style>
<style>
.dairy-title {
  color: skyblue;
}
.nav-title {
  font-size: 18px !important;
}
.v-btn:before {
  background-color: unset !important;
}
.divider {
  border-width: 2px;
}
.card-title-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
.paragraph {
  max-width: 1000px;
}
.screen-height {
  height: 87vh;
}

/* Text */
.primary--text {
  color: #a91a01;
}
</style>
